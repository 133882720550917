<template>
	<h1>Non-Latin Characters</h1>
	<div class="text-block bump-b-30">
		<p>
			For certain inputs it is crucial that we don't allow non-Latin character inputs. The demo below lets you
			test the validation function.
		</p>
		<br />
		<div id="form-wrap">
			<FormText v-model="string" placeholder="paste foreign script" />
			<FormButton :level="1" value="Verify" @click="verifyString" />
		</div>
		<p>
			&rsaquo; Default: <span class="code">{{ isNonLatin }}</span> &#8594; Detect an non-foldable
			(non-extended latin) characters.<br />
			&rsaquo; Strict mode: <span class="code">{{ isNonLatinStrict }}</span> &#8594; Detect any non-standard
			characters.<br />
			&rsaquo; Fully mode: <span class="code">{{ isNonLatinFully }}</span> &#8594; Check if string is entirely
			in foreign script.<br />
			&rsaquo; Urlified:&nbsp;<span class="code">{{ urlSafe }}</span>
		</p>
	</div>

	<div class="text-block bump-b-30">
		<h3>Modes</h3>
		<p>
			<b>Regular:</b> This will invalidate a string whenever there's any character that's outside Extended
			Latin character ranges, or if any of the diactritics fails to fold (eg. Đorđević).
		</p>
		<p>
			<b>Strict:</b> This will invalidate a string whenever there's any character outside the a-z 0-10 ranges.
			This is currently not used anwyhere. It is only relevant for label input, but this component has its own
			character logic built in.
		</p>
		<p>
			<b>Fully:</b> This will invalidate only when all characters fall outside the Extended Latin range. This
			is used in ArtistEdit.vue to detect if an artist's alternative name is in a foreign script, so we can
			store is as the foreignName.
		</p>
	</div>

	<div class="text-block bump-b-30">
		<h3>Applications</h3>
		<p>
			<b>Artist input</b>: Regular mode. If the name submitted doesn't fold nicely into a url friendly
			name-path, then we need to ask the user to provide us with an alterative Latin-character spelling.
		</p>
		<p>
			Details about the intracacies of non-latin character detection can be found in the comments of the
			non-latin function:
			<span class="code">use/NonLatin.js</span>
		</p>
	</div>

	<div id="demo-wrap" class="text-block bump-b-30">
		<h3>Characters to Test</h3>
		<p>Adjacent: Sağănēç (will pass) / Đorđević (won't pass)</p>
		<br />
		<p>Chinese: 在中國有大熊貓</p>
		<p>Japanese: 日本には寿司があります</p>
		<p>Hindi: भारत में हाथी हैं</p>
		<p>Arabic: في شبه الجزيرة العربية توجد الجمال</p>
		<p>Hebrew: בישראל יש פשעי זכויות אדם</p>
		<p>Urdu: پاکستان میں انہیں چائے پسند ہے۔</p>
		<p>
			Extended A:
			AĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſ
		</p>
		<p>
			Extended B:
			ƀƁƂƃƄƅƆƇƈƉƊƋƌƍƎƏƐƑƒƓƔƕƖƗƘƙƚƛƜƝƞƟƠơƢƣƤƥƦƧƨƩƪƫƬƭƮƯưƱƲƳƴƵƶƷƸƹƺƻƼƽƾƿǀǁǂǃǄǅǆǇǈǉǊǋǌǍǎǏǐǑǒǓǔǕǖǗǘǙǚǛǜǝǞǟǠǡǢǣǤǥǦǧǨǩǪǫǬǭǮǯǰǱǲǳǴǵǶǷǸǹǺǻǼǽǾǿȀȁȂȃȄȅȆȇȈȉȊȋȌȍȎȏȐȑȒȓȔȕȖȗȘșȚțȜȝȞȟȠȡȢȣȤȥȦȧȨȩȪȫȬȭȮȯȰȱȲȳȴȵȶȷȸȹȺȻȼȽȾȿɀɁɂɃɄɅɆɇɈɉɊɋɌɍɎɏ
		</p>
		<p>Extended C: ⱠⱡⱢⱣⱤⱥⱦⱧⱨⱩⱪⱫⱬⱭⱮⱯⱰⱱⱲⱳⱴⱵⱶⱷⱸⱹⱺⱻⱼⱽⱾⱿ</p>
		<p>
			Extended D:
			꜠꜡ꜢꜣꜤꜥꜦꜧꜨꜩꜪꜫꜬꜭꜮꜯꜰꜱꜲꜳꜴꜵꜶꜷꜸꜹꜺꜻꜼꜽꜾꜿꝀꝁꝂꝃꝄꝅꝆꝇꝈꝉꝊꝋꝌꝍꝎꝏꝐꝑꝒꝓꝔꝕꝖꝗꝘꝙꝚꝛꝜꝝꝞꝟꝠꝡꝢꝣ꟪꟫ꝦꝧꝨꝩꝪꝫꝬꝭꝮꝯꝰꝱꝲꝳꝴꝵꝶꝷꝸꝹꝺꝻꝼꝽꝾꝿꞀꞁꞂꞃꞄꞅꞆꞇꞈ꞉꞊ꞋꞌꞍꞎꞏꞐꞑꞒꞓꞔꞕꞖꞗꞘꞙꞚꞛꞜꞝꞞꞟꞠꞡꞢꞣꞤꞥꞦꞧꞨꞩꞪꞫꞬꞭꞮꞯꞰꞱꞲꞳꞴꞵꞶꞷꞸꞹꞺꞻꞼꞽꞾꞿꟀꟁꟂꟃꟄꟅꟆꟇꟈꟉꟊꟐꟑꟓꟕꟖꟗꟘꟙꟲꟳꟴꟵꟶꟷꟸꟹꟺꟻꟼꟽꟾꟿ
		</p>
		<p>
			Extended Additional:
			ḀḁḂḃḄḅḆḇḈḉḊḋḌḍḎḏḐḑḒḓḔḕḖḗḘḙḚḛḜḝḞḟḠḡḢḣḤḥḦḧḨḩḪḫḬḭḮḯḰḱḲḳḴḵḶḷḸḹḺḻḼḽḾḿṀṁṂṃṄṅṆṇṈṉṊṋṌṍṎṏṐṑṒṓṔṕṖṗṘṙṚṛṜṝṞṟṠṡṢṣṤṥṦṧṨṩṪṫṬṭṮṯṰṱṲṳṴṵṶṷṸṹṺṻṼṽṾṿẀẁẂẃẄẅẆẇẈẉẊẋẌẍẎẏẐẑẒẓẔẕẖẗẘẙẚẛẜẝẞẟẠạẢảẤấẦầẨẩẪẫẬậẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỂểỄễỆệỈỉỊịỌọỎỏỐốỒồỔổỖỗỘộỚớỜờỞởỠỡỢợỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹỺỻỼỽỾỿ
		</p>
	</div>
</template>

<script>
import FormText from '@/components/FormText'
import FormButton from '@/components/FormButton'

import { injectMetaData } from '@/use/MetaData'
import isNonLatin from '@/use/NonLatin'
import { urlify } from '@/use/StringMagic'

export default {
	name: 'DocMechanicsNonLatin',
	components: { FormText, FormButton },
	setup() {
		injectMetaData({
			title: 'Doc: Non-Latin',
		})
	},
	data() {
		return {
			string: '',
			isNonLatin: false,
			isNonLatinStrict: false,
			isNonLatinFully: false,
			urlSafe: '',
		}
	},
	methods: {
		verifyString() {
			this.isNonLatin = isNonLatin(this.string)
			this.isNonLatinStrict = isNonLatin(this.string, { strict: true })
			this.isNonLatinFully = isNonLatin(this.string, { fully: true })
			this.urlSafe = urlify(this.string)
		},
	},
}
</script>

<style scoped lang="scss">
#demo-wrap {
	word-break: break-all;
}
#form-wrap {
	display: flex;
	gap: 0.1rem;
	margin-bottom: 0.1rem;
}
</style>
